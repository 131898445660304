import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Divider, List, ListItem, ListItemIcon, Paper, Typography } from "@mui/material";
import React from "react";
import { resumeData } from "./resume/resumeData";
import LogoArea from "./resume/LogoArea";
import { ReactComponent as ListBullet } from "./resume/img/d20.svg";
import LinkedInLogo from './resume/img/linkedin.svg'

export default function Resume() {
  React.useEffect(() => {
    document.title = 'Alexander Burrows';
  }, []);
  
  return(
    <Container maxWidth='xl' sx={{mt: 2}}>
      <Paper elevation={3} sx={{mb: 2}}>
        <Typography variant="aHeader" component='div'>
          About Me
        </Typography>
        <Box sx={{p: 2}}>
          <Typography>
            I'm an experienced Internet software developer with a diverse background in programming, systems and database administration as well as team leadership.
            I have over twenty-five years of programming experience creating and supporting complex web applications and platforms in rapid software development environments.
          </Typography>
          <Typography sx={{mt: 2}}>
            I started programing when I was 12 and got handed a Commodore 64 and book on how to program in basic.
            I've been hooked ever since and always look forward to learning new languages or technologies that move this industry forward.
            Some think it can be challenging at times with how fast our industry moves but for me it keeps things interesting.
            Always something new to learn and play with.
          </Typography>
          <Box sx={{mt: 2}}>
            <a href='https://www.linkedin.com/in/alexander-burrows-03742126/' target='_new'>
              <Box component='img' alt='Linked In Logo' sx={{height: 32, width: 32, p: 1, mr: 2, borderRadius: 2, bgcolor: '#FFFFFF'}} src={LinkedInLogo} />
            </a>
          </Box>
        </Box>
      </Paper>
      <Paper elevation={3} sx={{mb: 2}}>
        <Typography variant="aHeader" component='div'>
          Work Experience
        </Typography>
        {resumeData.map((companies, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMore />} sx={{flexDirection: 'row-reverse'}} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
              <Typography sx={{fontWeight: '700', width: '50%', flexShrink: 0}}>{companies.company}</Typography>
              <Typography align="right" sx={{width: '50%', fontStyle: 'italic'}}>{companies.startDate} to {companies.endDate}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {companies.positions.map((pos, index) => (
                <React.Fragment key={index}>
                  <Typography sx={{ml: 2, fontWeight: '500'}}>{pos.title}</Typography>
                  <Typography sx={{ml: 2, fontWeight: '400', fontStyle: 'italic', fontSize: 14}}>Location: {pos.location}</Typography>
                  <List component='ul' sx={{ml: 1}}>
                    {pos.highlights.map((highL, index) => (
                      <React.Fragment key={index}>
                        <ListItem component='li'>
                          <ListItemIcon sx={{minWidth: 0, mr: 1}}><ListBullet height={16} width={16} /></ListItemIcon>
                          <Typography>{highL}</Typography>
                        </ListItem>
                        {(pos.highlights.length - 1 !== index) && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
      <Paper evelation={5} sx={{mb: 2}}>
        <Typography variant="aHeader" component='div' sx={{mb: 2}}>
          Known Technologies
        </Typography>
        <LogoArea />
      </Paper>
    </Container>
  );
}