export const resumeData = [
  {
    company: 'Shockoe Commerce Group LLC',
    startDate: 'Feb 2009',
    endDate: 'Present',
    positions: [
      {
        title: 'Web Developer / Website Consultant',
        location: 'Remote',
        highlights: [
          'Responsible for gathering monthly, quarterly and end of year tax reports.',
          'Wrote tools for gathering product and sales information to distribute to third party advertisers.',
          'Oversaw the transfer of store fronts from an outdated system to Shopify.',
          'Designed and developed a content management application that maintains website assets across six different transactional web applications from a single interface',
          'Developed centralized sales filtering and reporting tools for sales data coming from multiple web applications',
          'Integrated third party applications and functionality into existing web products',
          'Primary point of contact responsible for third party vendor technology and data management',
          'Designed and built tools to track and monitor affiliate traffic. This lead to an annual savings of over 15k a year',
          'Setup Google analytics to improve traffic source reporting and analysis'
        ]
      }
    ]
  }, {
    company: 'Self Entertainment',
    startDate: 'Aug 2003',
    endDate: 'Jan 2009',
    positions: [
      {
        title: 'Lead Software Engineer / Lead Web Developer',
        location: 'Grants Pass, OR',
        highlights: [
          'Mod_perl 1&2/ Apache developer brought in to automate and create new systems',
          'Performed work flow analysis and automated manual tasks to reduce work load by 85%.  The resulting efficiency gains lead to an increase in revenue of 100%',
          'Designed and developed a comprehensive inventory management system which included asset tracking, sales and inventory reporting and user auditing',
          'Developed a centralized tool suite capable of updating multiple websites from a single user interface',
          'Developed a secure web portal for overseas partner sites to easily access and deliver products to customers'
        ]
      }
    ]
  }, {
    company: 'Ticketmaster / Ticketmaster.com',
    startDate: 'Jun 2001',
    endDate: 'Jun 2003',
    positions: [
      {
        title: 'Software Engineer / Web Developer',
        location: 'Los Angeles, CA',
        highlights: [
          'Part of a team that rewrote the Ticketmaster.com core product.  After the rewrite, we gained a ten-fold increase in site efficiency.',
          'Developed web based tool sets for editorial and site layout modifications.  These tools enabled the editorial and marketing teams to supply the site with content and to modify template layout so that a new look and feel could be created for special marketing or editorial features.',
          'Developed the searching front-end for the Ticketmaster.com website.  My code takes user input, queries the search engine, parses, formats and then displays the results.',
          'Developed site localization/personalization which provides users with relevant site content based on geography.'
        ]
      }
    ]
  }, {
    company: 'Citysearch.com',
    startDate: 'Jun 1997',
    endDate: 'Jun 2001',
    positions: [
      {
        title: 'Database Administrator',
        location: 'Pasadena, CA',
        highlights: [
          'Administration of Oracle, MySQL and MSSQL databases.',
          'Responsible for optimizing queries for data tools/web tools.',
          'Coded perl interfaces for database lookup tools.',
        ]
      }, {
        title: 'UNIX System Administrator',
        location: 'Pasadena, CA',
        highlights: [
          'Responsible for the administration and general well being of hundreds of UNIX based systems.',
          'I was a key part of a datacenter build-out team responsible for the implementation of a new hosting environment.  Tasks included installation of hardware, OSs and production software.',
          'While in this position, I was able to reduce what would have been a 32-hour site outage due to hardware failure to a 3-hour outage with some imaginative thinking.',
          'Responsible for onsite and offsite tape backup and data storage.',
        ]
      }, {
        title: 'System Operations - Technical Team Lead',
        location: 'Pasadena, CA',
        highlights: [
          'Responsible for planning, coordination, and supervision of the computer system operations staff and providing highly responsive professional and technical support for internal and production systems.',
          'Monitoring and management of staging, production and network systems',
          'Managed activities of a 8-person 24/7 technology support group',
          'While in this position, I was technology lead for a customer product implementation in Montreal, Canada.  Responsibilities included configuration of hardware and OSs, installation of production software and training local staff on maintenance and best practices.',
          'Coded perl cgi support site that cataloged all machines and contact information for our site.'
        ]
      }
    ]
  },
];